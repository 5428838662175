import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Maps2Bitcanna from "./pages/mainnet/bitcanna";
import Maps2Uptick from "./pages/mainnet/uptick";
import Maps2Mantra from "./pages/testnet/mantra";
import Maps2Aura from "./pages/mainnet/aura";
import Maps2Sge from "./pages/mainnet/sge";
import Maps2Sget from "./pages/testnet/sge";
import Maps2Union from "./pages/testnet/union";
import Maps2Quicksilver from "./pages/mainnet/quicksilver";
import Maps2QuicksilverTestnet from "./pages/testnet/quicksilver";
import Maps2PryzmTestnet from "./pages/testnet/pryzm";
import Maps2Hypersignt from "./pages/testnet/hypersign";
import Maps2Humans from "./pages/mainnet/humans";
import Maps2Humanst from "./pages/testnet/humans";
import Maps2Blockxt from "./pages/testnet/blockx";
import Maps2Blockx from "./pages/mainnet/blockx";
import Maps2Namada from "./pages/testnet/namada";
import Maps2Swisstronik from "./pages/testnet/swisstronik";
import Maps2Oraichain from "./pages/mainnet/oraichain";
import Maps2Crossfi from "./pages/testnet/crossfi";
import Maps2Hedget from "./pages/testnet/hedge";
import Maps2Neutaro from "./pages/mainnet/neutaro";
import Maps2Wardent from "./pages/testnet/warden";
import Maps2Lambda from "./pages/mainnet/lambda";
import Maps2Nibiru from "./pages/mainnet/nibiru";
import Maps2CrossfiM from "./pages/mainnet/crossfi";
import Maps2Sidet from "./pages/testnet/side";
import Maps2Ogt from "./pages/testnet/og";
import Maps2Airchainst from "./pages/testnet/airchains";
import Maps2Initiat from "./pages/testnet/initia";
import Maps2Entangle from "./pages/mainnet/entangle";
import Maps2SedaM from "./pages/mainnet/seda";
import Maps2SelfchainM from "./pages/mainnet/selfchain";
import Maps2Symphonyt from "./pages/testnet/symphony";
import Maps2Empeiriat from "./pages/testnet/empeiria";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mainnet/bitcanna" element={<Maps2Bitcanna />} />
      <Route path="/mainnet/uptick" element={<Maps2Uptick />} />
      <Route path="/testnet/mantra" element={<Maps2Mantra />} />
      <Route path="/mainnet/aura" element={<Maps2Aura />} />
      <Route path="/mainnet/sge" element={<Maps2Sge />} />
      <Route path="/testnet/sge" element={<Maps2Sget />} />
      <Route path="/testnet/union" element={<Maps2Union />} />
      <Route path="/mainnet/quicksilver" element={<Maps2Quicksilver />} />
      <Route path="/testnet/quicksilver" element={<Maps2QuicksilverTestnet />} />
      <Route path="/testnet/pryzm" element={<Maps2PryzmTestnet />} />
      <Route path="/testnet/hypersign" element={<Maps2Hypersignt />} />
      <Route path="/mainnet/humans" element={<Maps2Humans />} />
      <Route path="/testnet/humans" element={<Maps2Humanst />} />
      <Route path="/testnet/blockx" element={<Maps2Blockxt />} />
      <Route path="/mainnet/blockx" element={<Maps2Blockx />} />
      <Route path="/testnet/namada" element={<Maps2Namada />} />
      <Route path="/testnet/swisstronik" element={<Maps2Swisstronik />} />
      <Route path="/mainnet/oraichain" element={<Maps2Oraichain />} />
      <Route path="/testnet/crossfi" element={<Maps2Crossfi />} />
      <Route path="/testnet/hedge" element={<Maps2Hedget />} />
      <Route path="/mainnet/neutaro" element={<Maps2Neutaro />} />
      <Route path="/testnet/warden" element={<Maps2Wardent />} />
      <Route path="/mainnet/lambda" element={<Maps2Lambda />} />
      <Route path="/mainnet/nibiru" element={<Maps2Nibiru />} />
      <Route path="/mainnet/crossfi" element={<Maps2CrossfiM />} />
      <Route path="/testnet/side" element={<Maps2Sidet />} />
      <Route path="/testnet/og" element={<Maps2Ogt />} />
      <Route path="/testnet/airchains" element={<Maps2Airchainst />} />
      <Route path="/testnet/initia" element={<Maps2Initiat />} />
      <Route path="/mainnet/entangle" element={<Maps2Entangle />} />
      <Route path="/mainnet/seda" element={<Maps2SedaM />} />
      <Route path="/mainnet/selfchain" element={<Maps2SelfchainM />} />
      <Route path="/testnet/symphony" element={<Maps2Symphonyt />} />
      <Route path="/testnet/empeiria" element={<Maps2Empeiriat />} />
      {/* Tambahkan rute lain sesuai kebutuhan */}
    </Routes>
  );
};

export default AppRoutes;
