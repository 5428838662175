import React from "react";
import MapsGeo from "../../../components/maps-geo";

function Oraichain() {
  return (
    <>
      <MapsGeo />
    </>
  );
}

export default Oraichain;
