import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";
//import Maps2 from "./pages/maps/Maps2";

/*
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Maps2Bitcanna from "./mainnet/bitcanna/Maps2";
import Maps2Uptick from "./mainnet/uptick/Maps2"
import Maps2Mantra from "./testnet/mantra/Maps2";
*/

import "leaflet/dist/leaflet.css";

function App() {
  return (
    <Router>
      <div
        style={{ width: "100%", display: "block", justifyContent: "center" }}
      >
        <AppRoutes />
      </div>
    </Router>
  );
}
/*
function App() {
  return (
    <div
      style={{
        width: "100%",
        display: "block",
        justifyContent: "center",
      }}
    >
      <Maps2 />
    </div>
  );
}
*/

/*
function App() {
  return (
    <Router>
      <div
        style={{
          width: "100%",
          display: "block",
          justifyContent: "center",
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mainnet/bitcanna" element={<Maps2Bitcanna />} />
          <Route path="/mainnet/uptick" element={<Maps2Uptick />} />
          <Route path="/testnet/mantra" element={<Maps2Mantra />} />
        </Routes>
      </div>
    </Router>
  );
}
*/
/*
const Home = () => {
  return (
    <div>
      <h2>Home Page</h2>
      {/* Tambahkan konten halaman utama di sini }
    </div>
  );
};
*/
export default App;
