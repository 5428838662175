import React from "react";
import MapsGeo from "../../../components/maps-geo";

function Aura() {
  return (
    <>
      <MapsGeo />
    </>
  );
}

export default Aura;
