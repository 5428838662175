// Maps2.jsx

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import ReactCountryFlag from "react-country-flag";
import countryList from "country-list";
import MarkerClusterGroup from "react-leaflet-cluster";
import "./styles.css";
import { fetchData } from "../../utils/fetch";

function MapsGeo() {
  const location = useLocation();
  const { pathname } = location;
  console.log(pathname);
  const [data, setData] = useState([]);
  const [center] = useState([48.769895, 8.094744]);
  const [zoom] = useState(3);
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/1301/1301421.png",
    iconSize: [38, 38],
  });

  useEffect(() => {
    fetchData(pathname)
      .then((res) => {
        const data = res;
        if (data.length > 0) {
          setData(res);
        }
      })
      .catch((err) => console.log("Error: ", err));
  }, [pathname]);

  const groupMarkersByLocation = () => {
    const groupedData = {};
    data.forEach((el) => {
      const loc = el.loc;
      if (groupedData[loc]) {
        groupedData[loc].push(el);
      } else {
        groupedData[loc] = [el];
      }
    });
    return groupedData;
  };

  const getProviderStats = () => {
    const providerStats = data.reduce((stats, el) => {
      const provider = el.provider;
      stats[provider] = (stats[provider] || 0) + 1;
      return stats;
    }, {});
    return providerStats;
  };

  const getCountryStats = () => {
    const countryStats = data.reduce((stats, el) => {
      const countryName = countryList.getName(el.country);
      stats[countryName] = (stats[countryName] || 0) + 1;
      return stats;
    }, {});
    return countryStats;
  };

  const providerStats = getProviderStats();
  const countryStats = getCountryStats();

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentProviderItems = Object.entries(providerStats).slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const currentCountryItems = Object.entries(countryStats).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalProviderPages = Math.ceil(
    Object.keys(providerStats).length / ITEMS_PER_PAGE
  );
  const totalCountryPages = Math.ceil(
    Object.keys(countryStats).length / ITEMS_PER_PAGE
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {data.length > 0 ? (
        <div className="maps-container">
          <div className="map-frame">
            <MapContainer center={center} zoom={zoom}>
              <TileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                attribution=""
                detectRetina={true}
              />
              <MarkerClusterGroup chunkedLoading>
                {Object.values(groupMarkersByLocation()).map((group, index) => (
                  <React.Fragment key={index}>
                    {group.map((el, subIndex) => (
                      <Marker
                        key={subIndex}
                        position={el.loc.split(",").map(parseFloat)}
                        icon={customIcon}
                      >
                        <Popup>
                          {`IP: ${el.remote_ip}`}
                          <br />
                          {`Provider: ${el.provider}`}
                          <br />
                          {`Node ID: ${el.id}`}
                          <br />
                          {`Moniker: ${el.moniker}`}
                          <br />
                          Country:{" "}
                          <ReactCountryFlag countryCode={el.country} svg />{" "}
                          {el.country}
                        </Popup>
                      </Marker>
                    ))}
                  </React.Fragment>
                ))}
              </MarkerClusterGroup>
            </MapContainer>
          </div>
          <div className="stats-container">
            <div className="table-frame">
              <div className="provider-table">
                <h2 style={{ color: "green" }}>Node per Provider</h2>
                <table style={{ color: "blue" }}>
                  <thead>
                    <tr>
                      <th>Provider</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentProviderItems.map(([provider, count]) => (
                      <tr key={provider}>
                        <td>{provider}</td>
                        <td>{count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-container">
                  {Array.from({ length: totalProviderPages }).map(
                    (_, index) => (
                      <button
                        key={index}
                        className={`pagination-button ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                        onClick={() => handlePageClick(index + 1)}
                      >
                        {index + 1}
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="table-frame">
              <div className="country-table">
                <h2 style={{ color: "green" }}>Node per Country</h2>
                <table style={{ color: "green" }}>
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCountryItems.map(([country, count]) => (
                      <tr key={country}>
                        <td>{country}</td>
                        <td>{count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-container">
                  {Array.from({ length: totalCountryPages }).map((_, index) => (
                    <button
                      key={index}
                      className={`pagination-button ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                      onClick={() => handlePageClick(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MapsGeo;
