export const fetchData = async (url) => {
  try {
    const response = await fetch(
      `https://api.konsortech.xyz${url}/location.json`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error("Gagal mengambil data");
    }

    const rawData = await response.text();
    const cleanedData = rawData.replace(/[^\x20-\x7E]+/g, "");
    const parsedData = JSON.parse(cleanedData);

    if (!Array.isArray(parsedData) || parsedData.length === 0) {
      throw new Error("Format data tidak valid");
    }

    //   setData(parsedData);
    return parsedData;
  } catch (error) {
    console.error("Error:", error.message);
  }
};
