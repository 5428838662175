import React from "react";
import MapsGeo from "../../../components/maps-geo";

function Lambda() {
  return (
    <>
      <MapsGeo />
    </>
  );
}

export default Lambda;
