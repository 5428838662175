// ...

const Home = () => {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h2>Welcome to the Home Page</h2>
        <p>
          This is a simple home page. You can add more content or features
          here.
        </p>
      </div>
    );
  };

export default Home;  // Pastikan mengekspor secara default

  // ...
  