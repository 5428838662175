import React from "react";
import MapsGeo from "../../../components/maps-geo";

function Entangle() {
  return (
    <>
      <MapsGeo />
    </>
  );
}

export default Entangle;
